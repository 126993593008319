export default {
    state: {
        currency: { value: 'UAH', text: 'UAH' },
    },
    mutations: {
        setCurrency(state, currency) {
            state.currency = currency.toUpdate
        },
    },
    actions: {
        async updateCurrency({ commit, getters }, toUpdate) {
            try {
                const updateData = { ...getters.currency, toUpdate }
                console.log(updateData,'UPDATE DATA', getters, toUpdate)
                commit('setCurrency', updateData)
            }

            catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        currency: s => s.currency
    }
}
