import requestService from "../requestService";
import store from "@/store";

export default {
    async getUserProfile() {
        const response = await requestService.get(`/v1/profile`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateUserProfile(form) {
        const response = await requestService.post(`/v1/profile`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateUserPassword(form) {
        const response = await requestService.post(`/v1/profile/change_password`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getBalance() {
        const response = await requestService.get(`/v1/profile/balance`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getBonuses() {
        const response = await requestService.get(`/v1/profile/bonus`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getBonusesByUserID(uuid) {
        const response = await requestService.get(`/cp_dash/user_bonus/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
}