<template>
  <v-navigation-drawer
    absolute
    top
    width="100%"
    v-model="showDrawer"
    style="
      background: #fafafa;
      backdrop-filter: blur(4px);
      min-height: 100svh;
      padding: 10px 0px;
    "
  >
    <v-main>
      <v-container style="min-height: 95svh; position: relative">
        <p
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            margin-bottom: 12px;
          "
        >
          Профіль
        </p>
        <div
          class="closeIcon"
          style="position: absolute; top: 12px; right: 20px"
          @click="$emit('close')"
        />
        <div
          style="height: 1px; width: 100%; background: rgba(148, 148, 148, 0.2)"
        ></div>
        <v-col class="px-0 py-0" v-if="loggedUserPTrans">
          <div class="myBonusesCard" style="margin-top: 20px">
            <p style="margin-bottom: 12px">
              {{ "avaibels_bonuses_label" | localize }}
            </p>
            <span
              style="
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: #fed500;
              "
              >{{ balance.toFixed(2) }}
              <img
                src="@/assets/img/iconsSvg/bonusIcon.svg"
                width="18px"
                height="19px"
                alt="bonus"
              />
            </span>
          </div>
          <v-col class="px-0 py-0" style="margin-top: 20px">
            <a v-for="item in profileItemList" :key="item.id" :href="item.path">
              <span>
                <div
                  class="listItem"
                  :class="$route.path == item.path ? 'activeListItem' : ''"
                >
                  <div :class="item.icon" style="margin-right: 12px" />
                  {{ item.title | localize }}
                </div>
              </span>
            </a>
          </v-col>
          <submit-button
            @click="logout"
            style="
              height: 48px !important;
              position: absolute;
              bottom: 0px;
              width: calc(100% - 25px);
            "
          >
            <div
              class="logoutIcon"
              style="background-color: #fafafa; margin-right: 12px"
            />
            {{ "logout_label" | localize }}</submit-button
          >
        </v-col>
        <v-col class="px-0 py-0" v-else style="text-align: center">
          <p
            style="
              color: #1b1b1b;
              text-align: center;
              font-family: 'MacPaw Fixel';
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-top: 40px;
              margin-bottom: 8px;
            "
          >
            Увійдіть в профіль
          </p>
          <span
            style="
              color: #4b5262;
              text-align: center;
              font-family: 'MacPaw Fixel';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
            >Щоб переглянути ваші квитки, потрібно авторизуватися</span
          >
          <img
            src="@/assets/img/mobileAuthIcon.png"
            style="margin: 20px 0px"
            width="280px"
            height="280px"
            alt="auth"
          />
          <submit-button
            style="height: 48px !important"
            @click="$router.push('/login')"
          >
            <div
              class="logoutIcon"
              style="
                background-color: #fafafa;
                margin-right: 12px;
                transform: rotate(180deg);
              "
            />
            {{ "login_label" | localize }}\{{
              "registration_label" | localize
            }}</submit-button
          >
          <v-row
            no-gutters
            align="center"
            justify="center"
            style="margin-top: 40px"
          >
            <div
              style="
                opacity: 0.25;
                background: #1b1b1b;
                width: calc(50% - 31px);
                height: 0.5px;
              "
            />
            <div
              style="
                margin: 0px;
                color: #4b5262;
                font-family: 'MacPaw Fixel';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0px 16px;
                width: 30px;
              "
            >
              {{ "or_login_with" | localize }}
            </div>
            <div
              style="
                opacity: 0.25;
                background: #1b1b1b;
                width: calc(50% - 31px);
                height: 0.5px;
              "
            />
          </v-row>
          <social-buttons />
        </v-col>
      </v-container>
    </v-main>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import SocialButtons from "../UI/Buttons/socialButtons.vue";
import submitButton from "../UI/Buttons/submitButton.vue";
import profileService from "../../requests/User/profileService";
export default {
  components: { submitButton, SocialButtons },
  data: () => ({
    showDrawer: true,
    activeProfileItem: "my_ticket",
    profileItemList: [
      {
        id: 1,
        title: "my_ticket_label",
        icon: "myTicketIcon",
        link: "my_ticket",
        path: "/profile/my-ticket",
      },
      {
        id: 2,
        title: "bonuses_label",
        icon: "profileBonusIcon",
        link: "bonuses",
        path: "/profile/bonuses",
      },
      {
        id: 3,
        title: "personal_data_label",
        icon: "settingIcon",
        link: "setting",
        path: "/profile/setting",
      },
    ],
    balance: 0,
  }),
  mounted(){
    this.getBalance()
  },
  methods: {
    logout() {
      this.$store.commit("clearUserLogged");
    },
    async getBalance() {
      await profileService.getBalance().then((res) => {
        if (res.status == "Success") {
          this.balance = res.data.balance;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
  watch: {
    showDrawer: {
      handler() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped>
.myBonusesCard {
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(51, 72, 135, 0) 0%, #172b69 100%),
    #144fa9;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  padding: 16px;
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  height: max-content;
}
.listItem {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  color: #1b1b1b;
}
.listItem:hover {
  color: #144fa9;
  background: #e7edf6;
}
.listItem:hover .myTicketIcon {
  background: #144fa9;
}
.listItem:hover .settingIcon {
  background: #144fa9;
}
.listItem:hover .profileBonusIcon {
  background: #144fa9;
}
.listItem:hover .logoutIcon {
  background: #144fa9;
}
.activeListItem {
  color: #144fa9;
  background: #e7edf6;
}
.activeListItem:hover .myTicketIcon {
  background: #144fa9;
}
.activeListItem:hover .settingIcon {
  background: #144fa9;
}
.activeListItem:hover .profileBonusIcon {
  background: #144fa9;
}
.activeListItem:hover .logoutIcon {
  background: #144fa9;
}
.activeListItem .myTicketIcon {
  background: #144fa9;
}
.activeListItem .settingIcon {
  background: #144fa9;
}
.activeListItem .profileBonusIcon {
  background: #144fa9;
}
.activeListItem .logoutIcon {
  background: #144fa9;
}
</style>