<template>
  <header
    color="transparent"
    :style="$vuetify.breakpoint.smAndDown ? '' : 'padding: 10px 0px'"
  >
    <v-main>
      <v-container
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin: 0px;padding-top:20px;padding-bottom:20px;'
            : ''
        "
      >
        <v-row no-gutters align="center">
          <router-link to="/">
            <img
              v-if="$route.path == '/'"
              src="@/assets/img/siteLogo.png"
              :width="$vuetify.breakpoint.smAndDown ? '107px' : '122px'"
              alt="sitelogo"
              style="object-fit: cover"
            />
            <img
              v-else
              src="@/assets/img/PTransBlackLogo.png"
              :width="$vuetify.breakpoint.smAndDown ? '107px' : '122px'"
              alt="sitelogo"
              style="object-fit: cover"
            />
          </router-link>
          <v-col
            style="padding-left: 16px"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-row no-gutters align="center" justify="center">
              <router-link
                v-for="item in headerItems"
                :key="item.id"
                :to="item.href"
                style="margin: 0px"
                ><p
                  class="linkItem"
                  :style="$route.path !== '/' ? 'color:#1B1B1B;' : ''"
                >
                  {{ item.title | localize }}
                </p></router-link
              >
            </v-row>
          </v-col>

          <v-col
            cols="3"
            class="py-0 px-0"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-row no-gutters justify="end">
              <!-- LANGUAGE DROPDOWN -->
              <v-menu
                offset-y
                open-on-hover
                :close-on-content-click="false"
                style="z-index: 90"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="menuDropdown"
                    :style="
                      $route.path == '/' ? 'color:#fff;' : 'color:#1B1B1B'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img
                      src="@/assets/img/Flags/UA.png"
                      alt="UA"
                      width="24px"
                      height="24px"
                      style="border-radius: 50%"
                      v-if="locale == 'UA'"
                    />
                    <img
                      src="@/assets/img/Flags/EN.png"
                      alt="EN"
                      width="24px"
                      height="24px"
                      style="border-radius: 50%"
                      v-if="locale == 'EN'"
                    />
                    <img
                      src="@/assets/img/Flags/PL.png"
                      alt="PL"
                      width="24px"
                      height="24px"
                      style="border-radius: 50%"
                      v-if="locale == 'PL'"
                    />
                    <span style="margin-left: 8px">
                      {{ locale }} \ {{ currency.value }}
                    </span>
                  </div>
                </template>
                <div class="menuCard">
                  <p style="margin-bottom: 12px">
                    {{ "language_label" | localize }}
                  </p>
                  <v-row no-gutters align="center">
                    <div
                      class="menuItemBox"
                      v-for="lang in languageList"
                      :key="lang.id"
                      :class="locale == lang.lang ? 'activeMenuItemBox' : ''"
                      @click="updateLocale(lang.lang)"
                    >
                      {{ lang.title }}
                    </div>
                  </v-row>
                  <p style="margin-bottom: 12px; margin-top: 20px">
                    {{ "currency_label" | localize }}
                  </p>
                  <v-row no-gutters align="center">
                    <div
                      class="menuItemBox"
                      v-for="item in currencyList"
                      :key="item.id"
                      :class="
                        currency.value == item.currency ? 'activeMenuItemBox' : ''
                      "
                      @click="
                        updateCurrency({
                          value: item.currency,
                          text: item.title,
                        })
                      "
                    >
                      {{ item.title }}
                    </div>
                  </v-row>
                </div>
              </v-menu>
              <!-- USER DROPDOWN -->
              <v-menu
                offset-y
                open-on-hover
                close-on-content-click
                nudge-left="40px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="menuDropdown"
                    :style="
                      $route.path == '/' ? 'color:#fff;' : 'color:#1B1B1B'
                    "
                    v-bind="attrs"
                    v-on="on"
                    style="width: 152px; z-index: 100"
                  >
                    <v-icon
                      :color="$route.path == '/' ? '#fff' : '#1B1B1B'"
                      style="margin-right: 8px"
                      size="24px"
                      >mdi-account</v-icon
                    >
                    {{ "header_my_ticket" | localize }}
                  </div>
                </template>
                <div
                  class="menuCard"
                  style="padding: 8px 0px; width: 240px"
                  v-if="loggedUserPTrans"
                >
                  <router-link
                    v-for="item in profileItemList"
                    :key="item.id"
                    :to="item.path"
                  >
                    <div class="listItem">
                      <div :class="item.icon" style="margin-right: 12px" />
                      {{ item.title | localize }}
                    </div>
                  </router-link>
                  <router-link
                    to="/admin"
                    v-if="
                      loggedUserPTrans.role == 'admin' ||
                      loggedUserPTrans.role == 'manager' ||
                      loggedUserPTrans.role == 'dispatcher'
                    "
                  >
                    <div class="listItem">
                      <div class="myProfileIcon" style="margin-right: 12px" />
                      {{ "admin_label" | localize }}
                    </div>
                  </router-link>
                  <div class="sectionLine" style="margin: 8px 0px"></div>
                  <div class="listItem" @click="logout">
                    <div class="logoutIcon" style="margin-right: 12px" />
                    {{ "logout_label" | localize }}
                  </div>
                </div>
                <div
                  class="menuCard"
                  style="padding: 8px 0px; width: 240px"
                  v-else
                >
                  <div class="listItem" @click="$router.push('/login')">
                    <div
                      class="logoutIcon"
                      style="margin-right: 12px; transform: rotate(180deg)"
                    />
                    {{ "sign_in_btn" | localize }}
                  </div>
                </div>
              </v-menu>
            </v-row>
          </v-col>
          <v-row no-gutters justify="end" v-if="$vuetify.breakpoint.smAndDown">
            <v-menu
              offset-y
              open-on-hover
              :close-on-content-click="false"
              style="z-index: 100"
            >
              <template v-slot:activator="{ on, attrs }">
                <div style="padding: 4px 12px">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="languageIcon"
                    :style="
                      $route.path == '/' ? 'background-color: #fafafa' : ''
                    "
                  />
                </div>
              </template>
              <div class="menuCard">
                <p style="margin-bottom: 12px">
                  {{ "language_label" | localize }}
                </p>
                <v-row no-gutters align="center">
                  <div
                    class="menuItemBox"
                    v-for="lang in languageList"
                    :key="lang.id"
                    :class="locale == lang.lang ? 'activeMenuItemBox' : ''"
                    @click="updateLocale(lang.lang)"
                  >
                    {{ lang.title }}
                  </div>
                </v-row>
                <p style="margin-bottom: 12px; margin-top: 20px">
                  {{ "currency_label" | localize }}
                </p>
                <v-row no-gutters align="center">
                  <div
                    class="menuItemBox"
                    v-for="item in currencyList"
                    :key="item.id"
                    :class="
                      currency.value == item.currency ? 'activeMenuItemBox' : ''
                    "
                    @click="updateCurrency({
                      value: item.currency,
                      text: item.title,
                    })"
                  >
                    {{ item.title }}
                  </div>
                </v-row>
              </div>
            </v-menu>
            <div
              style="padding: 4px 12px"
              @click="$emit('showProfileNavigationDrawer')"
            >
              <div
                class="userMobileIcon"
                :style="$route.path == '/' ? 'background-color: #fafafa' : ''"
              />
            </div>
            <div
              style="padding: 4px 12px"
              @click="$emit('showNavigationDrawer')"
            >
              <div
                class="menuMobileIcon"
                :style="$route.path == '/' ? 'background-color: #fafafa' : ''"
              />
            </div>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HeaderComponent",
  data: () => ({
    headerItems: [
      {
        id: 1,
        title: "header_main",
        href: "/",
      },
      {
        id: 2,
        title: "header_our_routes",
        href: "/routes",
      },
      {
        id: 3,
        title: "header_rent_bus",
        href: "/rent_bus",
      },
      {
        id: 4,
        title: "header_blog",
        href: "/blog",
      },
    ],
    languageList: [
      {
        id: 1,
        title: "Укр",
        lang: "UA",
      },
      {
        id: 2,
        title: "Eng",
        lang: "EN",
      },
      {
        id: 3,
        title: "Pl",
        lang: "PL",
      },
    ],
    currencyList: [
      {
        id: 1,
        title: "UAH",
        currency: "UAH",
      },
      {
        id: 2,
        title: "$",
        currency: "USD",
      },
      {
        id: 3,
        title: "zł",
        currency: "PLN",
      },
    ],
    profileItemList: [
      {
        id: 1,
        title: "my_orders_label",
        icon: "myTicketIcon",
        path: "/profile/my-ticket",
      },
      {
        id: 2,
        title: "personal_data_label",
        icon: "myProfileIcon",
        path: "/profile/setting",
      },
    ],
  }),
  methods: {
    ...mapActions(["updateLocale"]),
    ...mapActions(["updateCurrency"]),
    logout() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["locale"]),
    ...mapGetters(["currency"]),
    ...mapGetters(["loggedUserPTrans"]),
  },
};
</script>

<style scoped>
.linkItem {
  margin: 0px 32px 0px 0px !important;
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-right: 32px;
}
a {
  text-decoration: none;
}
.menuDropdown {
  padding: 8px 16px;
  width: 134px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: MacPaw Fixel Display;
  z-index: 90;
}
.menuDropdown:hover {
  border-radius: 10px;
  background: rgba(245, 245, 245, 0.1);
}
.menuCard {
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: #1b1b1b;
  font-family: MacPaw Fixel Display;
  font-size: 16px;
  font-weight: 600;
}
.menuItemBox {
  padding: 8px 12px;
  border-radius: 10px;
  background: #fcfcfc;
  width: max-content;
  height: max-content;
  font-weight: 400;
  cursor: pointer;
  margin-right: 10px;
}
.menuItemBox:hover {
  background: #e7edf6;
}
.activeMenuItemBox {
  background: #144fa9 !important;
  box-shadow: 0px 4px 4px 0px rgba(20, 158, 81, 0.1);
  color: #fafafa;
}
.listItem {
  color: #4b5262;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
}
.listItem:hover {
  background: #e7edf6;
  color: #144fa9;
}
.listItem:hover .myTicketIcon {
  background-color: #144fa9;
}
.listItem:hover .myProfileIcon {
  background-color: #144fa9;
}
.listItem:hover .logoutIcon {
  background-color: #144fa9;
}
</style>